import React from 'react';
import Link from '../link/Link';
import Image from '../Image';
import { SCREEN_SIZES } from '../../../utils/constants';
import useWindowSize from '../../../hooks/useWindowSize';

const HomeSections = (): JSX.Element => {
  const { documentWidth } = useWindowSize();

  return (
    <div className="homeSections restyle-home homeInTop">
      <div className="maxContainer">
        <Link href="/libros" className="bannerSection">
          <img src='img/homerestyle/librera.jpg' alt='libros' className='imgHome' />
          <img src='img/homerestyle/libreraMv.jpg' alt='libros' className='imgHomeMv' />
          <p className="noPad">Libros</p>
        </Link>
        <Link href="/ebooks" className="bannerSection">
          <img src='img/homerestyle/ebook.jpg' alt='libros' className='imgHome' />
          <img src='img/homerestyle/ebookMv.jpg' alt='libros' className='imgHomeMv' />
          <p className="noPad">Ebooks</p>
        </Link>

        <Link href="/modelos-anatomicos-educativos" className="bannerSection">
          <Image
            src={`img/homerestyle/banner-modelos-n.${
              documentWidth > SCREEN_SIZES.MAX_MOBILE ? 'png' : 'webp'
            }`}
            addCdnPrefix
            alt="Modelos"
            width="300"
            height="200"
          />
          <p className="noPad">Modelos</p>
        </Link>

        <Link href="/material-y-suministros-medicos" className="bannerSection">
          <Image
            src={`img/homerestyle/banner-marierial-n.${
              documentWidth > SCREEN_SIZES.MAX_MOBILE ? 'png' : 'webp'
            }`}
            addCdnPrefix
            alt="Material"
            width="300"
            height="200"
          />
          <p className="noPad">Material</p>
        </Link>

        <Link href="/poster" className="bannerSection">
          <Image
            src={`img/homerestyle/banner-laminas-n.${
              documentWidth > SCREEN_SIZES.MAX_MOBILE ? 'png' : 'webp'
            }`}
            addCdnPrefix
            alt="Material"
            width="300"
            height="200"
          />
          <p className="noPad">Láminas</p>
        </Link>
      </div>
    </div>
  );
};

export default HomeSections;
